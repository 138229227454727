import React, { useState } from "react";
import styled from "styled-components";
import Footer from "../components/Footer";
import LayoutSection from "../components/layout/LayoutSection";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import SEO from "../components/seo";
import SiteHeader from "../components/SiteHeader";
import colors from "../components/style/colors";
import H1 from "../components/typography/H1";
import H3 from "../components/typography/H3";
import P1 from "../components/typography/P1";
import "../main.css";

const TabsWrapper = styled.div``;

const TabLink = styled(P1)`
  cursor: pointer;
  padding: 0.4em 0.6em;
  margin: 0;
  color: ${colors.grey};
  ${props =>
    props.selected &&
    `
  color: ${colors.accent1}
  border-bottom: 2px solid ${colors.accent1};
`};
  @media (max-width: 960px) {
    padding: 0.5em 0 0.3em;
  }
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 960px) {
    display: block;
  }
`;

const Tab = styled.div`
  ${props => props.selected && `color: red`}
`;

const TabPanel = styled.div`
  display: none;
  padding-top: 4em;
  ${props => props.selected && `display: block`}
`;

const UpdateDiv = styled.div`
  font-size: 1.12em;
  text-align: ${props => (props.center ? "center" : "left")};
  color: ${props => (props.color ? colors[props.color] : colors.dark)};
  margin: 0.1em 0 0.6em;
  font-weight: 500;
  line-height: 1.7;
`;

export default function UpdatesPage(props) {
  const { apps } = props.pageContext;

  const [selectedTab, setSelectedTab] = useState(0);

  const Tabs = apps.map((app, index) => (
    <Tab key={index} selected={index == selectedTab} onClick={() => setSelectedTab(index)}>
      <TabLink selected={index == selectedTab}>{Object.keys(app)[0]}</TabLink>
    </Tab>
  ));

  const Panels = apps.map((app, index) => {
    const Items = Object.values(app)[0].item.map((item, index) => (
      <div key={index}>
        <H3>{item.title}</H3>
        <UpdateDiv dangerouslySetInnerHTML={{ __html: item.description }} />
      </div>
    ));
    return (
      <TabPanel key={index} selected={index == selectedTab}>
        {Items}
      </TabPanel>
    );
  });

  return (
    <LayoutWrapper>
      <SEO
        title="Boxy Suite Updates"
        description="What's new with Boxy Suite? Here are all the changelogs of our Gmail, Calendar, Keep and Contacts clients for Mac."
      />
      <SiteHeader dark />
      <LayoutSection width="56em">
        <H1 secondary center>
          Boxy Suite Updates
        </H1>
        <TabsWrapper>
          <Menu>{Tabs}</Menu>
          {Panels}
        </TabsWrapper>
      </LayoutSection>
      <Footer />
    </LayoutWrapper>
  );
}
